
import { defineComponent, computed, ref } from 'vue';
import {
	IconTrophy,
	IconUserCircle,
	IconSwords,
	IconSortAscending2,
	IconSettings,
	IconLogout,
	IconDeviceGamepad,
	IconMenu2
} from '@tabler/icons-vue';

// Stores
import { useUserStore } from '@/store/User';

// VueRouter
import { useRouter } from 'vue-router';

// Components
import Notifications from '@/components/notifications/Notifications.vue';

// PrimeVUe
import PMenu from 'primevue/menu';

export default defineComponent({
	name: 'SideBar',
	components: {
		// PrimeVue
		PMenu,

		// Components
		Notifications,

		// Icons
		IconTrophy,
		IconUserCircle,
		IconSwords,
		IconSortAscending2,
		IconDeviceGamepad
	},
	props: {
		isExpanded: {
			type: Boolean,
			required: false,
			default: false
		},
		isMobile: {
			type: Boolean,
			required: false,
			default: false
		}
	},
	emits: ['log-out', 'settings', 'expand'],
	setup(props, { emit }) {
		// Vue Router
		const router = useRouter();

		// Stores
		const userStore = useUserStore();

		const user = computed(() => userStore.user);
		const currentPathName = computed(() => router.currentRoute.value.name);

		const logOut = async () => {
			emit('log-out');
		};

		const navigationItems = computed(() => [
			{
				to: '/torneos',
				icon: IconTrophy,
				text: 'Torneos',
				key: 'tournaments'
			},
			{
				to: '/partidos',
				icon: IconDeviceGamepad,
				text: 'Mis Partidas',
				key: 'matches'
			},
			{
				to: `/jugador/${user.value?.uuid}`,
				icon: IconUserCircle,
				text: 'Mi Perfil',
				key: 'playerProfile'
			},
			{
				to: '/ranking',
				icon: IconSortAscending2,
				text: 'Ranking',
				key: 'ranking'
			},
			{
				to: '/lobby',
				icon: IconSwords,
				text: 'Scrims',
				key: 'lobby'
			}
		]);

		const handleExpansion = () => {
			emit('expand');
		};

		// Mobile menu
		const mobileMenu = ref();
		const mobileMenuItems = ref([
			{
				label: 'Navegacion',
				items: [
					{
						label: 'Torneos',
						icon: 'pi pi-home',
						command: () => router.push({ name: 'tournaments' })
					},
					{
						label: 'Mis Partidas',
						icon: 'pi pi-star',
						command: () => router.push({ name: 'matches' })
					},
					{
						label: 'Mi Perfil',
						icon: 'pi pi-user',
						command: () =>
							router.push({
								name: 'playerProfile',
								params: { playerUuid: user.value?.uuid }
							})
					},
					{
						label: 'Ranking',
						icon: 'pi pi-list',
						command: () => router.push({ name: 'ranking' })
					},
					{
						label: 'Scrims',
						icon: 'pi pi-shield',
						command: () => router.push({ name: 'lobby' })
					}
				]
			},
			{
				label: 'Otros',
				items: [
					{
						label: 'Configuracion',
						icon: 'pi pi-cog',
						command: () => router.push({ name: 'settings' })
					},
					{
						label: 'Salir',
						icon: 'pi pi-sign-out',
						command: logOut
					}
				]
			}
		]);
		const toggleMenu = (event: any) => {
			mobileMenu.value.toggle(event);
		};

		return {
			user,
			navigationItems,
			currentPathName,
			logOut,
			handleExpansion,

			// Mobile menu
			mobileMenu,
			mobileMenuItems,
			toggleMenu,

			// Icons
			IconMenu2,
			IconSettings,
			IconLogout
		};
	}
});
