
// Vue
import { defineComponent, PropType, computed } from 'vue';

// Icons
import { IconCheck, IconX, IconCalendar, IconClock } from '@tabler/icons-vue';

// Types
import { PlayerUnreadNotificationDetails } from '@/submodules/shared-types/notifications/dto/get-player-unread-notifications.dto';

export default defineComponent({
	name: 'Notification',
	components: {
		// Icons
		IconCalendar,
		IconClock
	},
	props: {
		notification: {
			type: Object as PropType<PlayerUnreadNotificationDetails>,
			required: true
		}
	},
	emits: ['accept-team-invitation', 'reject-team-invitation'],
	setup(props) {
		const createdAtDate = new Date(props.notification.createdAt);

		const notificationDate = computed(() => {
			return createdAtDate.toLocaleDateString('es-ES', {
				day: '2-digit',
				month: '2-digit',
				year: 'numeric'
			});
		});

		const notificationHour = computed(() => {
			return createdAtDate.toLocaleTimeString('es-ES', {
				hour: '2-digit',
				minute: '2-digit'
			});
		});

		return {
			notificationDate,
			notificationHour,

			// Icons
			IconCheck,
			IconX
		};
	}
});
