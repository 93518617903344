import { resolveComponent as _resolveComponent, resolveDirective as _resolveDirective, createVNode as _createVNode, withDirectives as _withDirectives, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, resolveDynamicComponent as _resolveDynamicComponent, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0366c5f6"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "profile-img-wrapper" }
const _hoisted_2 = { class: "notifications-wrapper" }
const _hoisted_3 = { class: "main-navigation-wrapper" }
const _hoisted_4 = { class: "icon-wrapper" }
const _hoisted_5 = { class: "bottom-wrapper" }
const _hoisted_6 = { class: "logo-wrapper" }
const _hoisted_7 = { class: "logo" }
const _hoisted_8 = {
  key: 1,
  class: "mobile-menu-wrapper"
}
const _hoisted_9 = { class: "notifications-wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MAvatar = _resolveComponent("MAvatar")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_Notifications = _resolveComponent("Notifications")!
  const _component_MButton = _resolveComponent("MButton")!
  const _component_MImage = _resolveComponent("MImage")!
  const _component_PMenu = _resolveComponent("PMenu")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (!_ctx.isMobile)
    ? (_openBlock(), _createElementBlock("nav", {
        key: 0,
        id: "side-bar-nav",
        class: _normalizeClass({ expanded: _ctx.isExpanded })
      }, [
        (_ctx.user)
          ? (_openBlock(), _createBlock(_component_router_link, {
              key: 0,
              to: `/jugador/${_ctx.user?.uuid}`,
              style: {"text-decoration":"none"}
            }, {
              default: _withCtx(() => [
                _createElementVNode("span", _hoisted_1, [
                  _withDirectives(_createVNode(_component_MAvatar, {
                    "img-url": _ctx.user.profileImgUrl,
                    name: _ctx.user.name,
                    rounded: "",
                    size: 55
                  }, null, 8, ["img-url", "name"]), [
                    [
                      _directive_tooltip,
                      'Ver perfil',
                      void 0,
                      { rigth: true }
                    ]
                  ])
                ])
              ]),
              _: 1
            }, 8, ["to"]))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_Notifications)
        ]),
        _createElementVNode("ul", _hoisted_3, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.navigationItems, (item, index) => {
            return (_openBlock(), _createElementBlock("li", {
              key: index,
              class: _normalizeClass({ selected: _ctx.currentPathName === item.key })
            }, [
              _createVNode(_component_router_link, {
                to: item.to,
                class: "nav-item"
              }, {
                default: _withCtx(() => [
                  _withDirectives((_openBlock(), _createElementBlock("span", _hoisted_4, [
                    (_openBlock(), _createBlock(_resolveDynamicComponent(item.icon)))
                  ])), [
                    [_directive_tooltip, _ctx.isExpanded ? '' : item.text]
                  ]),
                  _createElementVNode("p", null, _toDisplayString(item.text), 1)
                ]),
                _: 2
              }, 1032, ["to"])
            ], 2))
          }), 128))
        ]),
        _createElementVNode("div", _hoisted_5, [
          _withDirectives(_createVNode(_component_MButton, {
            class: "settings-btn",
            icon: _ctx.IconSettings,
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('settings')))
          }, null, 8, ["icon"]), [
            [
              _directive_tooltip,
              'Configuración',
              void 0,
              { right: true }
            ]
          ]),
          _withDirectives(_createVNode(_component_MButton, {
            icon: _ctx.IconLogout,
            onClick: _ctx.logOut
          }, null, 8, ["icon", "onClick"]), [
            [
              _directive_tooltip,
              'Salir',
              void 0,
              { right: true }
            ]
          ])
        ]),
        _createElementVNode("span", _hoisted_6, [
          _createElementVNode("span", _hoisted_7, [
            _createVNode(_component_MImage, {
              src: 
						require('@/submodules/shared-front/assets/images/logos/ElUnderLogo.svg')
					,
              alt: "Logo de El Under Esports"
            }, null, 8, ["src"])
          ])
        ])
      ], 2))
    : (_openBlock(), _createElementBlock("div", _hoisted_8, [
        _createElementVNode("span", _hoisted_9, [
          _createVNode(_component_Notifications)
        ]),
        _createElementVNode("span", null, [
          _createVNode(_component_MButton, {
            rounded: "",
            icon: _ctx.IconMenu2,
            onClick: _ctx.toggleMenu
          }, null, 8, ["icon", "onClick"])
        ]),
        _createVNode(_component_PMenu, {
          id: "responsive_menu",
          ref: "mobileMenu",
          model: _ctx.mobileMenuItems,
          popup: ""
        }, null, 8, ["model"])
      ]))
}