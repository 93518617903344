
import { defineComponent, computed, ref } from 'vue';

// Vue Router
import { useRouter } from 'vue-router';

// Stores
import { useUserStore } from '@/store/User';
import { useNotificationsStore } from '@/store/Notifications';
import { useTeamsStore } from '@/store/Teams';

// Icons
import { IconBell } from '@tabler/icons-vue';

// PrimeVue
import OverlayPanel from 'primevue/overlaypanel';

// Component
import Notification from '@/components/notifications/Notification.vue';
import { useToast } from 'primevue/usetoast';

export default defineComponent({
	name: 'Notifications',
	components: {
		OverlayPanel,

		// Componentes
		Notification
	},
	setup() {
		// Stores
		const userStore = useUserStore();
		const notificationsStore = useNotificationsStore();

		// VueRouter
		const router = useRouter();

		// Prime Vue
		const primeToast = useToast();

		const user = computed(() => userStore.user);
		const notifications = computed(() => notificationsStore.notifications);

		const op = ref();
		const toggle = (event: MouseEvent) => {
			op.value.toggle(event);
		};

		const teamsStore = useTeamsStore();
		const acceptTeamInvitation = async (
			notificationId: number,
			teamUuid: string
		) => {
			if (!user.value) return;
			try {
				await teamsStore.answerTeaminvitation({
					notificationId,
					teamUuid,
					playerUuid: user.value.uuid,
					acceptInvitation: true
				});

				await notificationsStore.fetchNotifications();

				router.push({
					name: 'teamProfile',
					params: { teamUuid }
				});
				op.value.toggle(true);
			} catch (error) {
				console.error(
					'Error al aceptar la invitacion al equipo: ',
					error
				);
				primeToast.add({
					severity: 'error',
					summary: 'Error',
					detail: 'Error al aceptar la invitacion al equipo',
					life: 3000
				});
			}
		};
		const rejectTeamInvitation = async (
			notificationId: number,
			teamUuid: string
		) => {
			if (!user.value) return;
			try {
				await teamsStore.answerTeaminvitation({
					notificationId,
					teamUuid,
					playerUuid: user.value.uuid,
					acceptInvitation: false
				});
				await notificationsStore.fetchNotifications();
			} catch (error) {
				console.error(
					'Error al rechazar la invitacion del equipo: ',
					error
				);
				primeToast.add({
					severity: 'error',
					summary: 'Error',
					detail: 'Error al rechazar la invitacion del equipo',
					life: 3000
				});
			}
		};

		return {
			op,
			toggle,
			notifications,
			IconBell,
			acceptTeamInvitation,
			rejectTeamInvitation
		};
	}
});
