
import { defineComponent, computed } from 'vue';

export default defineComponent({
	name: 'MButton',
	inheritAttrs: false,
	props: {
		icon: {
			type: Function,
			required: false,
			default: () => null
		},
		severity: {
			type: String as () =>
				| 'danger'
				| 'info'
				| 'warning'
				| 'success'
				| 'dark',
			required: false,
			default: 'info'
		},
		rounded: {
			type: Boolean,
			required: false,
			default: false
		},
		outlined: {
			type: Boolean,
			required: false,
			default: false
		},
		size: {
			type: String as () => 'small' | 'medium' | 'large',
			required: false,
			default: 'small'
		},
		hoverable: {
			type: Boolean,
			required: false,
			default: true
		}
	},
	emits: [],
	setup(props) {
		const btnClass = computed(() => {
			const btnClass = [];
			if (props.rounded) btnClass.push('rounded');
			if (props.outlined) btnClass.push('outlined');
			if (props.hoverable) btnClass.push('hoverable');
			return btnClass;
		});

		return {
			btnClass
		};
	}
});
